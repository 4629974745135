import { InteractiveBrowserCredential } from '@azure/identity'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ButtonGroup, Col, Container, Row, Table, ToggleButton } from 'react-bootstrap'
import { CLIENT_ID, TENANT_ID } from '../../../authConfig'
import { useSpinner } from '../../../spinner/SpinnerContext'
import { BlobServiceClient } from '@azure/storage-blob'
import { FileUploader } from 'react-drag-drop-files'
import { blobToString } from '../../../blobs/blobs.service'
import { DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_BLOBS, CUSTOM_JSON_THEME } from '../../../common/common.constants'
import { Product2, MissionControlOrderData, Listing__c, MissionControlOrderItem, Inventory__c, MissionControlContact } from '../../../common/common.interfaces'
import Papa from 'papaparse'
import { WootOrder } from './woot.interfaces'
import { DateTime } from 'luxon'
import { ENVIRONMENT_RADIO, SALESFORCE_URLS_BY_ENVIRONMENT } from '../../../common/common.entities'
import BlobModalComponent from '../../../blobs/BlobModalComponent'
import JSONPretty from 'react-json-pretty'
import { notify } from '../../../notify/notify'

const WOOT_CONSTANTS = {
  integration: 'a033l000011v2rCAAQ', // Manual Order
  account: '0016O00003XcljaQAB',
  listingSuffix: '_US',
  fulfillmentWarehouseId: '0016O00003XcrBlQAJ', // EC WOOT
}

const COUNTRY_MAP = {
  'USA': 'US'
}

const DS_ORDERS_IMPORT = 'order-service/inbound/new/amazon/woot'

interface WootMappedRow {
  salesforceObjects: {
    order: MissionControlOrderData,
    orderItems: MissionControlOrderItem[],
    contact: MissionControlContact
  },
  inventory: Inventory__c,
  product: Product2,
  listing: Listing__c,
  wootOrder: WootOrder
}

const WootOrdersImport = () => {
  const { showSpinner, hideSpinner } = useSpinner()
  
  const [environment, setEnvironment] = useState('production')
  const [importContent, setImportContent]: [WootOrder[], any] = useState(undefined)
  const [products, setProducts]: [Product2[], any] = useState(undefined)
  const [listings, setListings]: [Listing__c[], any] = useState(undefined)
  const [inventory, setInventory]: [Inventory__c[], any] = useState(undefined)
  const [mappedContent, setMappedContent]: [WootMappedRow[], any] = useState(undefined)
  const [debugContent, setDebugContent]: [WootMappedRow, any] = useState(undefined)
  const [modalVisible, setModalVisible]: [boolean, any] = useState(false)

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })

  /** Load Product Data */
  useEffect(() => {
    const load = async () => {
      await loadProducts()
      await loadListings()
      await loadEauClaireInventory()
    }

    const loadProducts = async () => {
      showSpinner()
      try {
        const dsBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
        const dsStorageContainerService = dsBlobServiceClient.getContainerClient('storage')
      
        const blobClient = dsStorageContainerService.getBlobClient(`product-service/products/product-ids-and-pricebookentries.json`)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json = JSON.parse(downloaded)
        setProducts(json)
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    const loadListings = async () => {
      showSpinner()
      try {
        const dsBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
        const dsStorageContainerService = dsBlobServiceClient.getContainerClient('storage')
      
        const blobClient = dsStorageContainerService.getBlobClient(`listing-service/listings/_US.json`)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json = JSON.parse(downloaded)
        setListings(json)
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    const loadEauClaireInventory = async () => {
      showSpinner()
      try {
        const dsBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
        const dsStorageContainerService = dsBlobServiceClient.getContainerClient('storage')
      
        const blobClient = dsStorageContainerService.getBlobClient(`inventory-service/EC_WOOT.json`)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json = JSON.parse(downloaded)
        setInventory(json)
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    load()
  }, [environment])

  const handleFileChanged = useCallback((file) => {
    if(!file) return
    showSpinner()

    try {
      const contentReader = new FileReader()
      if (file) contentReader.readAsText(file)

      contentReader.onload = (e) => {
        Papa.parse<WootOrder>(file, {
          header: true, // Assumes the first row is headers
          skipEmptyLines: true,
          dynamicTyping: { Quantity: true, Cost: true }, // Convert numeric values
          complete: (result) => {
            const orders: WootOrder[] = result.data
            setImportContent(orders)
          },
        })
      }
    }catch(error){
      console.error(error)
    }
    hideSpinner()
  }, [])

  // Content Changed
  useEffect(() => {
    if(!importContent) return
    const mapped: WootMappedRow[] = []
    for(const order of importContent){
      let address = order.Address1
      if(order.Address2 && order.Address2.trim().length > 0){
        address = `${address}\n${order.Address2}`
      }

      const orderDate = DateTime.fromFormat(order.OrderDate, 'M/d/yyyy h:mm:ss a').toISO()
      const orderNumber = `WOOT-${order.OrderNumber}`
      const mappedOrder: MissionControlOrderData = {
        AccountId: WOOT_CONSTANTS.account,
        Customer_Name__c: order.Name,
        Customer_Phone_Number__c: order.Phone,
        Name: orderNumber,
        Order_Integration__c: WOOT_CONSTANTS.integration,
        Order_Time__c: orderDate,
        EffectiveDate: orderDate,
        Estimated_Ship_Date__c: DateTime.fromFormat(order.RequiredShipDate, 'M/d/yyyy h:mm:ss a').toISO(),
        Shipping_Address_Name__c: order.Name,
        ShippingCity: order.City,
        ShippingCountry: COUNTRY_MAP[order.Country],
        ShippingPostalCode: `${order.PostalCode}`,
        ShippingState: order.State,
        ShippingStreet: `${address}`,
        Order_Discount_Amount__c: 0,
        Order_Tags__c: order.Win,
        Shipping_Carrier_Requested__c: order.RequiredCarrier,
        Shipping_Class_Requested__c: order.RequiredShipMethod,
        Shipping_Collected__c: 0,
        Status: 'Draft',
        Tax_Collected__c: 0,
        Pricebook2Id: '01s1N0000066ylwQAA',
        Unique_External_ID__c: orderNumber,
        Marketplace_Buyer_ID__c: `${order.OrderNumber}@WOOT.com`,
        SYS_CustomOrderData__c: order.PurchaseOrderNumber
      }

      const relatedListing = listings.find(l => l.SKU__c.toLowerCase() === order.PartNumber.toLowerCase())
      if(!relatedListing){
        console.error(`Failed to find listing for ${order.PartNumber} [${order.OrderNumber}]`)
        continue
      }

      const relatedProduct = products.find(p => p.Id === relatedListing.Product__c)
      if(!relatedProduct){
        console.log(`Failed to find product for ${relatedListing.SKU__c} with product id[${relatedListing.Product__c}]`)
        continue
      }

      const relatedInventory = inventory.find(i => i.Product__c === relatedProduct.Id)
      if(!relatedInventory){
        console.error(`Failed to find EC WOOT inventory for ${relatedListing.SKU__c} with product id[${relatedProduct.Id}] `)
        continue
      }

      const mappedOrderItem: MissionControlOrderItem = {
        PricebookEntryId: relatedProduct.PricebookEntryId,
        Product2Id: relatedProduct.Id,
        Quantity: order.Quantity,
        UnitPrice: order.Cost,
        Line_Item_Title__c: order.Description,
        Listing__c: relatedListing.Id,
        Fulfillment_Warehouse__c: WOOT_CONSTANTS.fulfillmentWarehouseId,
        Inventory__c: relatedInventory.Id
      }

      const nameSplit = order.Name.split(' ', 2)

      const mappedContact: MissionControlContact = {
        AccountId: WOOT_CONSTANTS.account,
        Business_Phone__c: order.Phone,
        Email: `${order.OrderNumber}@WOOT.com`,
        FirstName: nameSplit[0],
        LastName: nameSplit[1],
        MailingCity: order.City,
        MailingCountry: COUNTRY_MAP[order.Country],
        MailingPostalCode: order.PostalCode,
        MailingState: order.State,
        MailingStreet: address,
        OtherCity: order.City,
        OtherCountry: COUNTRY_MAP[order.Country],
        OtherPostalCode: order.PostalCode,
        OtherState: order.State,
        OtherStreet: address
      }

      mapped.push({
        salesforceObjects: {
          order: mappedOrder,
          orderItems: [mappedOrderItem],
          contact: mappedContact
        },
        inventory: relatedInventory,
        listing: relatedListing,
        product: relatedProduct,
        wootOrder: order
      })
    }
    setMappedContent(mapped)
  }, [importContent, products, listings, inventory])

  const dragDropAreaView = useMemo(() => {
    return (
      <>
        <FileUploader handleChange={handleFileChanged} name="file" label="Import a WOOT! Orders CSV file." types={["CSV"]} />
      </>
    )
  }, [])

  const handleDebugClicked = useCallback((e) => {
    const relatedOrder = mappedContent.find(c => c.wootOrder.OrderNumber === e.target.id)
    setDebugContent(relatedOrder)
    setModalVisible(true)
  }, [mappedContent])

  const handleImportOrdersClicked = useCallback((e) => {
    const importOrders = async () => {
      if(!mappedContent) return

      showSpinner()
      try {
        const dsBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
        const dsStorageContainerService = dsBlobServiceClient.getContainerClient('storage')
        for(const content of mappedContent){
          try {
            const blobClient = dsStorageContainerService.getBlobClient(`${DS_ORDERS_IMPORT}/${content.salesforceObjects.order.Name}.json`)
            const stringy = JSON.stringify(content.salesforceObjects)
            await blobClient.getBlockBlobClient().upload(stringy, stringy.length)
            notify('success', `Successfully sent [${mappedContent.length}] order(s) to be loaded into Mission Control!`)
            setMappedContent(undefined)
            setDebugContent(undefined)
            setImportContent(undefined)
          }catch(error){
            console.error(error)
            notify('error', `Failed to import orders! Please notify an administrator.`)
          }
        }
      }catch(error){
        console.log(error)
        notify('error', `Failed to import orders! Please notify an administrator.`)
      }
      hideSpinner()
    }
    importOrders()
  }, [mappedContent, environment])

  const closeModal = useCallback((e) => {
    setModalVisible(false)
  }, [])

  const view = useMemo(() => {
    return <div>
      <h2>WOOT! Orders Import</h2>
      <hr/>
      <Container>
        <Row>
          <Col>
            <ButtonGroup>
              {ENVIRONMENT_RADIO.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                  name="radio"
                  value={radio.value}
                  checked={environment === radio.value}
                  onChange={(e) => setEnvironment(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
          <Col>
              {dragDropAreaView}
          </Col>
        </Row>
        <Row>
          <Col>
              <Button variant='success' onClick={handleImportOrdersClicked}>Import Orders</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped hover bordered style={{fontSize: '14px'}}>
              <thead>
                <tr>
                  <th>Order Number</th>
                  <th>Part Number</th>
                  <th>Win</th>
                  <th>Quantity</th>
                  <th>Required Ship Date</th>
                  <th>Inventory</th>
                  <th>Debug</th>
                </tr>
              </thead>
              <tbody>
                {mappedContent?.map(c => {
                  return <tr>
                    <td>{c.wootOrder.OrderNumber}</td>
                    <td>{c.wootOrder.PartNumber}</td>
                    <td>{c.wootOrder.Win}</td>
                    <td>{c.wootOrder.Quantity}</td>
                    <td>{c.wootOrder.RequiredShipDate}</td>
                    <td>
                      <a href={`${SALESFORCE_URLS_BY_ENVIRONMENT[environment]}/${c.inventory.Id}`} target='_blank' rel='noopener noreferrer'>{c.inventory.Name} [{c.inventory.Fulfillable_Inventory__c}]</a>
                    </td>
                    <td><Button id={c.wootOrder.OrderNumber} onClick={handleDebugClicked}>Debug</Button></td>
                  </tr>
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <BlobModalComponent blobData={<JSONPretty data={debugContent} theme={CUSTOM_JSON_THEME} style={{fontSize: '1.5em'}} ></JSONPretty>} blobName={`Order #: ${debugContent?.wootOrder?.OrderNumber}`} closeModal={closeModal} modalVisible={modalVisible}></BlobModalComponent>
      
    </div>
  }, [environment, debugContent, mappedContent, modalVisible])

  return view
}

export default WootOrdersImport
