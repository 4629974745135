export enum EnvVar {
  AzureRedirectUri = 'AZURE_REDIRECT_URI'
}

export const ENVIRONMENT_RADIO = [
  { name: 'Development', value: 'development' },
  { name: 'Production', value: 'production' }
]

export const SALESFORCE_URLS_BY_ENVIRONMENT = {
  'development': 'https://nethealthshopsllc--testing.sandbox.lightning.force.com',
  'production': 'https://nethealthshopsllc.lightning.force.com'
}